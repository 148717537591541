<template>
  <div class="body">
    <section class="the-panel">
      <header id="logo">
        <div>
          <img id="headerImg" src="../assets/pic1.jpg" alt="logo" />
        </div>
      </header>

      <header>
        <!--  This is top heading showing the Home, Go back, OV chosen, purchase button  -->
        <span id="top-buttons">
          <router-link to="/" class="go-back-btn">Home</router-link>
          <router-link to="/oil-results/all" class="go-back-btn"
            >Go Back</router-link
          >
        </span>
        <p id="flavor-choice">Current flavor choice is:</p>
        <h2 id="OVChoice">{{ OVFromStore }}</h2>
        <a :href="`${OVLinkFromStore}`" target="_blank">
          <p id="buy-now">Buy Now</p>
        </a>
      </header>
      <!-- This is the loading image in-between data fetching. -->
      <section></section>
      <pre class="language-json"></pre>
<!-- 
      <label for="filters"></label>
      <select id="filters" name="filters" v-model="filterFoods">
        <option :value="''" disabled selected>Choose a filter</option>
        <option value="">All</option>
        <option value="vegetarian">Vegetarian</option>
        <option value="glutenFree">GlutenFree</option>
      </select> -->

      <div id="recipe-information">
        Alter to your liking. Always make sure food is cooked thoroughly.
        Recipes may have missing information. Use best judgment, some recipes
        may not align with exact results.
      </div>

      <!-- This whole div is displaying the recipe results after choosing a food. -->
      <div id="recipes-of-food-choice">
        <!-- <tr v-for="recipe in filteredRecipes" v-bind:key="recipe.id"> -->
             <tr v-for="recipe in recipes" v-bind:key="recipe.id">
          <router-link
            v-bind:to="{
              name: 'specific-recipe',
              params: { specificRecipe: recipe.id },
            }"
          >
            <!-- This is each recipe card individually -->
            <div id="box" v-on:click="storeOvLink()">
              <h2 id="title">{{ recipe.title }}</h2>
              <img id="foodPics" v-bind:src="recipe.image"  loading="lazy"  />
            </div>
          </router-link>
          <br />
        </tr>

        <div id="loading-l" v-if="isLoading">
          <p >Loading...</p>
          <img  class="loading"  src="../assets/giphy.gif" />
        </div>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
      <router-link to="/oil-results/all" class="go-back-btn"
        >Go Back</router-link
      >

      <!-- This whole footer is responsible for displaying social media icons and copyright. -->
      <footer>
        <ul id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ul>
        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
      </footer>
    </section>
  </div>
</template>

<script>
import apiServices from "../services/APIServices.js";
import services from "../services/services.js";
export default {
  props: {},

  data() {
    return {
      isLoading: true,
      recipes: [],
      // fullRecipe: [],
      searchTerm: "",
      // filterFoods: "",
      OVFromStore: "",
      OVLinkFromStore: "",
      oilData: [],
    };
  },
  created() {
    this.searchTerm = this.$route.params.foodName;
    this.OVFromStore = this.$store.state.oVName;
    this.searchRecipes();

    services.getOVByName(this.OVFromStore).then((response) => {
      this.oilData = response.data;
      this.OVLinkFromStore = this.oilData.link;
    });
  },

  computed: {
    // This is the filter option to filter recipes based off vegetarian, glutenFree
    // filteredRecipes() {
    //   return this.fullRecipe.filter((response) => {
    //     if (this.filterFoods == "") {
    //       return response;
    //     } else if (this.filterFoods == "vegetarian" && response.vegetarian) {
    //       return response;
    //     } else if (this.filterFoods == "glutenFree" && response.glutenFree) {
    //       return response;
    //     }
    //   });
    // },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    searchRecipes() {
      apiServices.searchRecipes(this.searchTerm).then((response) => {
        this.recipes = response.data;
        this.isLoading = false;
        this.filler();
        // let ids = "";

        // try {
        //   for (let i = 0; i < 30; i++) {
        //     ids += this.recipes[i].id;
        //     if (i < 29) {
        //       ids += ",";
        //     }
        //     this.isLoading = false;
        //   }
        // } catch (error) {
        //   console.log("");
        // }

        // apiServices.filteredRecipes(ids).then((response) => {
        //   this.fullRecipe = response.data;
        // });
      });
    },
    // Grabbing the buy link for purchase
    storeOvLink() {
      let blank = "";
      this.$store.commit("STORE_OV_LINK", blank);
      this.$store.commit("STORE_OV_LINK", this.OVLinkFromStore);
    },

    filler() {
      for (let i = 0; i < this.image; i++) {
        if (this.recipe[i].image == "null") {
          this.recipe[i].image = "pasta.jpg";
        }
      }
    },
  },
};
</script>

<style scoped>

.body {
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  min-height: 100%;
  height: fit-content;
}


#logo {
  width: auto;
  outline: 7px solid rgb(211, 207, 207);
}

#recipes-of-food-choice {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-grow: 1;
}

#top-buttons {
  display: flex;
  justify-content: space-evenly;
}

p {
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
}

.the-panel {
  background-color: #b9b7b7;
}

#headerImg {
  display: flex;
  object-fit: cover;
  flex-grow: 1;
  height: 300px;
}

#OVChoice {
  background-color: rgba(132, 147, 161, 0.349);
  font-weight: bolder;
  font-size: 1.4rem;
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  margin-left: 28%;
  margin-right: 28%;
}

#box {
  display: block;
  border: 3px solid black;
  background: rgba(72, 86, 100, 0.678);
  border-radius: 15px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  margin: 3px;
  height: auto;
  width: 200px;
}


#flavor-choice {
  padding-bottom: 10px;
}

.loading {
  display: block;
  justify-content: center;
  margin-left: 10%;
  border-radius: unset;
  border-radius: 20%;
  border: unset;
}

#loading-l {
  border: unset;
  
}

#recipe-information {
  color: rgba(228, 228, 228, 0.952);
  font-size: 1.2rem;
  background-color: rgba(132, 147, 161, 0.623);
}

#filters {
  font-size: 1.6rem;
  background-color: rgba(146, 188, 195, 0.733);
  margin-bottom: 10px;
  padding: 5px;
}

div {
  border: 2px solid rgb(0, 0, 0);
  background-color: rgba(165, 177, 189, 0.904);
  font-weight: bold;
  padding: 20px;
}

#logo div > img {
  display: flex;
  max-height: auto;
  width: 100%;
  margin: 0 auto;
}

#buy-now {
  border: 2px solid black;
  background: rgba(143, 143, 143, 0.658);
  color: rgba(0, 0, 255, 0.733);
  border-radius: 100px;
  margin-top: 20px;
  font-size: 1.7rem;
  margin: 10px;
  margin-left: 45%;
  margin-right: 45%;
}

#social-icons {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  align-items: flex-end;
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;
}

#trade-mark {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  list-style-type: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.609);
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 28px;
}

nav ul {
  list-style-type: none;
  margin: 0 10px 0 10px;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: flex;
  display: inline-flex;
  justify-content: space-around;
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 20px;
  align-items: center;
}

nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

nav a:hover {
  color: #2591da; /*Hover for directory items*/
  padding-left: 5px;
}

.fab {
  color: #313131; /*app icon color*/
}

#social-icons :hover .fab {
  color: #2591da;
}

#trade-mark {
  color: #000;
}

#title {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: initial;
  margin-bottom: 10px;
  block-size: fit-content;
  font-size: 1rem;
}

h2 {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 1.1rem;
  border: 2px solid black;
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  background-color: rgba(165, 177, 189, 0.904);
}
#social-icons1 {
  font-size: 2.5rem;
}


#foodPics {
  width: 140px;
  height: auto;
  padding-bottom: 30px;
  text-decoration: none;
  border-radius: 5%;
  border-bottom-left-radius: 13%;
  border-bottom-right-radius: 13%;
}

@media (max-width: 1024px) {
  #go-back-btn {
    font-size: 1.8rem;
  }

  .fa-3x {
    font-size: 1.5rem;
  }

#box {
  display: table-caption;
}

  #buy-now {
    font-size: 0.8rem;
    margin: 10px;
    margin-left: 40%;
    margin-right: 40%;
  }
  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
@media (max-width: 450px) {
  #go-back-btn {
    font-size: 1.5rem;
  }

#loading{
  height: 50px;
  width: auto;
}


  #buy-now {
    font-size: 0.8rem;
    margin: 10px;
    margin-left: 40%;
    margin-right: 40%;
  }

#headerImg {
  height: 200px;
}
  nav ul li {
    font-size: 2rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
</style>