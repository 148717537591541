<template>
  <div class="body">
    <section id="middle-panel">
      
        <header id="logo">
          <img src="../assets/sign15.png" />
        </header>
     
      <h1>Recipe Finder</h1>
      <nav>
        <ul>
          <div>
            <li>
              <router-link to="/oil-results/all">Recipes That Go With Oils / Vinegars</router-link>
            </li>
            <li>
              <router-link to="/food-results">Find Oils / Vinegars By Food Name</router-link>
            </li>
            <li>
              <a href="https://blueherontradingcompany.com/" target="_blank">Blue Heron Home Page</a>
            </li>
            <li>
               <router-link to="/contact-us">Contact Us</router-link>
            </li>
          </div>
        </ul>
      </nav>
      <footer>
        <ol id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-icons1"  class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/" target="_blank"
              ><i id="social-icons1"  class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ol>
      
        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
      </footer>
    </section>

      <img id="right-panel" src="../assets/das-oils.jpg" />
 
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.body {
  display: flex;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
 }

#middle-panel {
  display: grid;
  flex-direction: column;
  flex-basis: 100%;
  width: auto;
  background-color: #b9b7b7; /* left background #a5a5a5; */
  border: 7px solid rgba(182, 147, 50, 0.849);
  box-shadow:inset 0 0 0 5px rgb(36, 49, 107);
  height: auto;
}

#right-panel {
  display: flex;
  flex-direction: column; 
  width: 50%;
  max-height: 100%;
  object-fit: cover; 
} 

#social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;

}
#social-icons1 {
  font-size: 2.5rem;
}



h1 {
  display: flex;
  justify-content: center;
  font-family: "Kalam", cursive;
  margin-bottom: -100px;
  padding-top: 10px;
  font-size: 2rem;
  font-weight: bolder;
  color: #1b1b1b;
}

#logo {
  /* margin: 0 auto; */
  height: 300px;
  width: 200px;
}

header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 0%;
  margin: 0 auto;
  height: 300px;
  width: 300px;
}

nav ul {
  list-style-type: none;
  margin: 0 10px 0 10px;
  padding: 0;
  text-align: center; 
}

nav ul li {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1.5px solid #707070;
  padding-top: 55px;
  flex-wrap: wrap;
  flex-grow: 1;
}

nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

nav a:hover {
  color: #2591da;
  padding-left: 5px;
}

.fab {
  color: #313131; 
}

#social-icons li a:hover .fab {
  color: #2591da;
}

#trade-mark {
  display: flex;
  padding-left: 10px;
  position: fixed;
}

@media (max-width: 1024px) {
  #middle-panel {
    flex-basis: 100%;
  }

nav ul li{
  font-size: 1.8rem;
    margin-bottom: 4%;
}

  #logo {
    margin-bottom: -7%;
    height: 350px;
  }
  

  h1 {
    font-size: 3.5rem;
    margin-bottom: 90px;
    margin-top: 15px;
    padding-top: unset;
  }

  #right-panel {
    width: 200px;
    height: 100%;
    display: none;

  }

  #social-icons {
    margin-top: unset;
  }


#trade-mark {
  position: fixed;
}

}
@media (max-width: 450px) {

  h1 {
    font-size: 2.5rem;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  #middle-panel {
    flex-basis: 100%;
    width: auto;
  }
  #logo {
 height: auto;
 transform: scale(0.2, 0.2); 
 margin-top: -130%;
 margin-bottom: -120%;
  }

  #right-panel {
    display: none;
  }

  nav ul li {
    font-size: 1.2rem;
    padding: unset;
    padding: 10px;
  }

 .social-icons {
    margin-top: 20px;
    color: #313131;
    font-size: 1.8rem;
  }
}
</style>