import axios from 'axios';

const http = axios.create({
  baseURL: "https://olive-vue.herokuapp.com"
  // baseURL: "http://localhost:8080"
});
export default{

getOils() {
    return http.get('/get-allov')
},
getFoods() {
  return http.get('/get-foods')
},

getFoodSearchResults(oil) {
  return http.get(`/get-foods/byov/${oil}`)
},

getOVSearchResults(food) {
  return http.get(`/get-ov/by/food/${food}`)
},

getOVByName(name){
  return http.get(`/get-ov/by/${name}`)
}

}