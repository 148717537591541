<template>
  <div class="body">
    <section class="the-panel">
      <header id="logo">
        <div id="headerImg">
          <img  src="../assets/fire2.jpg" alt="logo" />
        </div>
      </header>

      <header>
        <span id="top-buttons">
        <router-link to="/" class="go-back-btn">Home</router-link>
        <!-- This will take us back to listOfRecipes.vue with the correct OV -->
        <router-link v-bind:to="{name: 'list-of-food-recipes', params: { foodName: foodPath}}" class="go-back-btn">Go Back</router-link>
        </span>
      </header>

        <p id="flavor-choice">Current flavor choice is:</p>
       <h2 id="OVChoice">{{OVFromStore}}</h2>
          <a :href="`${OVLinkFromStore}`" target="_blank" > 
       <p id="buy-now">Buy Now</p>
       </a>
      
      <pre class="language-json"></pre>

      <!-- This whole div is displaying the recipe results after choosing a food. -->
      <div id="recipe-of-food-choice">
        <section id="recipe">
          <h2 id="title">{{ recipes.title }}</h2>

          <br />
          <img id="foodPics" v-bind:src="recipes.image" />
          <section id="ready-in">
          <!-- This is the display for how many minutes with how many servings. -->
            <p id="minutes-servings" >
              Ready in: {{recipes.readyInMinutes}} minutes! 
             <br>
              This recipe has {{recipes.servings}} servings.
            </p>
         
          <br />
          <ol>
          <div class="box" v-for="option in recipeSteps" v-bind:key="option.number">
 
        <input type="checkbox" id="checkbox" class="box" > 
        <div id="steps-css">
        <li for="box" id="steps">{{option.step}}</li>
        </div>   
          </div>
          </ol>
          </section>
        </section>

<!-- Here we're creating the layout for the ingredients so they're displayed how we want. -->
<section  > <section id="click-to-mark">Click to mark off ingredients:</section>
   <div class="ingredients"  v-on:click="clicked"   v-for="ingredient in ingredients" v-bind:key="ingredient.id" >
      <th> {{ ingredient.name }}</th><br>
      <th>  {{ ingredient.amount.us.value }} {{ ingredient.amount.us.unit }} </th>
   </div>
</section>


<!-- This is the loading image in-between data fetching. -->
        <div v-if="isLoading">
             <p>Loading...</p>
          <img class="loading"  src="../assets/giphy.gif" />
        </div>
      </div>
    <router-link v-bind:to="{name: 'list-of-food-recipes', params: { foodName: foodPath}}" class="go-back-btn">Go Back</router-link>

      <!-- This whole footer is responsible for displaying social media icons and copyright. -->
      <footer>
        <ul id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/" target="_blank"
              ><i id="social-icons1" class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ul>
        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
      </footer>
    </section>
  </div>
</template>

<script>
import apiServices from "../services/APIServices.js";
export default {
  props: {},

  data() {
    return {
      isLoading: true,
      specificIngredientClicked: false,
      recipes: [],
      recipeSteps: [],
      completed: false,
      ingredients: [],
      id: Number,
      OVFromStore: "",
      OVLinkFromStore: "",
      foodPath: "",
    };
  },
  // Calling our functions
  created() {
    this.id = this.$route.params.specificRecipe;
    this.getSpecificRecipe();
    this.getStepByStepInstructions();
    this.getIngredients();
    this.OVFromStore = this.$store.state.oVName;
    this.OVLinkFromStore = this.$store.state.oVLink;
    this.foodPath = this.$store.state.foodPath;
},


  
  mounted() {
    window.scrollTo(0, 0);    
  },
  methods: {
    // This is grabbing the header name, image, and servings / minutes. 
    getSpecificRecipe() {
      apiServices.getSpecificRecipe(this.id).then((response) => {
        this.recipes = response.data;
        this.isLoading = false;
      });
    },
// This is the step-by-step directions on how to cook recipe.
    getStepByStepInstructions() {
      apiServices.getStepByStepInstructions(this.id).then((response) => {
        for (let i = 0; i < response.data[0].steps.length; i++) {
          this.recipeSteps.push(response.data[0].steps[i]);
        }
        this.isLoading = false;
      }).catch((error) => {
          if (error.response) {
            console.warn("Not Available Option");
          }
        });
    },
// Responsible for displaying the ingredients only
    getIngredients() {
      apiServices.getIngredients(this.id).then((response) => {
        this.ingredients = response.data.ingredients;
        this.isLoading = false;
      }).catch((error) => {
          if (error.response) {
            console.warn("Not Available Option");
          }
        });
    },
// We're using two classes to switch between colors when clicked.
clicked(event){
  if(event.target.classList.contains("ingredients")){
    event.target.classList.toggle("clickedIngredients");
  } 
}
  },
  
};
</script>


<style scoped>
  
 .body {
  display: flex;
  margin: 0 auto;
  padding: 0;
}

input[type=checkbox]:checked + div  { 
  color:rgb(165, 50, 50);
  text-decoration: line-through;
} 

#click-to-mark {
  background-color: rgba(143, 143, 143, 0.658);
  border: solid 2px black;
     border-radius: 5px;
  box-shadow: 0 0 0 1pt rgb(211, 207, 207);
  color: black;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

#recipe-of-food-choice {
  display: flex;
  justify-content: center;
  text-align:center;
  flex-grow: 1;
  font-weight: bold;
  font-size: 1.4rem;
  color: black;

}

#recipe {
  border: 3px solid black;
  background-color: rgba(72, 86, 100, 0.644);
  outline: 2px solid rgb(0, 0, 0);
  margin: 25px;

}
#minutes-servings {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  border: 3px solid black;
  border-radius: 5px;
  box-shadow: 0 0 0 1pt rgb(211, 207, 207);
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  white-space: pre;
  background: rgba(165, 177, 189, 0.904);
  word-spacing: 2px;
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 20%;
  margin-right: 20%;
}

#flavor-choice {
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
}

tr {
  padding-left: 10px;
  padding-right: 10px;
}

.ingredients {
  -webkit-transition: background 1.3s; /* For Safari */
  transition: background 1.3s; /* For modern browsers */
  padding-bottom: 35px;
  padding-top: 35px;
  padding-right: 55px;
  padding-left: 5px;
  border: 10px solid;
  border-radius: 10px;
  padding-top: 8%;
  margin-top: 20px;
  padding-bottom: 8%;
  font-size: 1.3rem;
  font-weight: bold;
  color: black;
  background-color:  rgba(167, 121, 35, 0.925);
}

.clickedIngredients {
  -webkit-transition: background 1.3s; /* For Safari */
  transition: background 1.3s; /* For modern browsers */
  padding-bottom: 35px;
  padding-top: 35px;
  padding-right: 55px;
  padding-left: 5px;
  border: 10px solid;
  border-radius: 10px;
  padding-top: 8%;
  margin-top: 20px;
  padding-bottom: 8%;
  font-size: 1.3rem;
  font-weight: bold;
  color: black;
  background-color:  rgb(165, 50, 50);
}



#OVChoice {
  background-color: rgba(132, 147, 161, 0.349);
  font-weight: bolder;
  font-size: 1.4rem;
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  margin-left: 25%;
  margin-right: 25%;
}

#top-buttons {
  display: flex;
  justify-content: space-evenly;
}

#ready-in {
  font-size: 1rem;
  color: black;
  font-weight: 600;
}

#title {
  display: flex;
  justify-content: center;
  font: 2rem;
}

.the-panel {
  background-color: #b9b7b7;
}

#headerImg {
  display: flex;
  object-fit: cover;
  flex-grow: 1;
  height: 300px;
  width: auto;
}

#steps {
  margin-left: 15px;
  font-size: 1.3rem;
}

#checkbox  {
transform : scale(1.5); 
outline-color: unset;
cursor: pointer;
box-shadow: 0 0 0 2pt rgb(0, 0, 0);
color:  rgb(36, 49, 107);
margin-right: 20px;
}


#checkbox2  {
transform : scale(1.5); 
outline-color: unset;
cursor: pointer;
box-shadow: 0 0 0 2pt rgb(0, 0, 0);
color:  rgb(36, 49, 107);
margin-right: 20px;
}
#steps-css {
  border-radius: 5px;
  width: auto;
}

.box {
  display: flex;
  border: 3px solid black;
  background: rgba(132, 147, 161, 0.281);
  outline: 3px solid rgb(211, 207, 207);
  word-spacing: 2px;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: bold;
  margin-left: -10px;
  margin:13px;
}


.loading {
  display: flex;
  justify-content: center;
  border: unset;
  border-radius: 25%;
}

#loading-l {
  text-decoration: unset;
  font-size: 1.3rem;
  color:  black;
}

div {
  border: 2px solid rgb(0, 0, 0);
  background-color: rgba(165, 177, 189, 0.904);
  font-weight: bold;
  padding: 10px;
  color: #000;
}

#logo div > img {
  display: flex;
  max-height: auto;
  width: 100%;
  margin: 0 auto;
}

#social-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;
}
#buy-now {
  border: 2px solid black;
  background: rgba(143, 143, 143, 0.658);
  color: rgba(0, 0, 255, 0.733);
  border-radius: 100px;
  margin-top: 20px;
  font-size: 1.7rem;
  margin: 10px;
  margin-left: 45%;
  margin-right: 45%; 
}


#trade-mark {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  list-style-type: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.609);
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 28px;
}

nav ul {
  list-style-type: none;
 
  padding: 0;
  text-align: center; 
}

nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

nav a:hover {
  color: #2591da; 
 
}

.fab {
  color: #313131; 
}

#social-icons1{
  font-size: 2.5rem;
}

#social-icons :hover .fab {
  color: #2591da;
}

#trade-mark {
  color: #000;
}
h2 {
  display: flex;
  justify-content: center;
  color: black;
  background: rgba(165, 177, 189, 0.904);
  font-size: 1.4rem;
  border: 3px solid black;
  border-radius: 5px;
  box-shadow: 0 0 0 1pt rgb(211, 207, 207);
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 10px;
  font-weight: 600;
  margin: 10px;
}

#foodPics {
  width: 350px;
  height: auto;
  padding-bottom: 30px;
  text-decoration: none;
  border-radius: 5%;
}

@media (max-width: 1024px) {

  #go-back-btn {
    font-size: 2.1rem;
  }

  h2 {
   margin: 10px;
   margin-left: 80px;
   margin-right: 80px;
  }

.ingredients{
  font-size: 1rem;
  margin-right: 10px;
  padding-right: auto;
}

.clickedIngredients {
  font-size: 1rem;
 margin-right: 10px;
  padding-right: auto;
}

#steps{
  font-size: 1rem;
}

  #foodPics {
    width: 300px;
  height: 200px;
  }

#minutes-servings  {
  display: flex;
  flex-wrap: wrap;
  padding: none;
  transform : scale(1.2);
  font-size: 0.8rem; 
}

  #buy-now {
    font-size: 1.2rem;
    margin: 10px;
    margin-left: 40%;
    margin-right: 40%;
  }
#headerImg {
  height: 250px;
}

#ingredients {
  font-size: 1rem;
  padding-top: unset;
  margin-top: 20px;
}

  .fa-3x {
    font-size: 1.5rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
@media (max-width: 450px) {
  #go-back-btn {
    font-size: 2rem;
  }
  h2 {
   transform : scale(1); 
   margin: 10px;
   margin-left: 80px;
   margin-right: 80px;
  }
#checkbox {
transform : scale(2); 
}

#foodPics {
  width: 250px;
  height: auto;
}

#headerImg {
  height: 200px;
}

#steps{
  font-size: 0.9rem;
  font-weight: bolder;
}

#minutes-servings  {
padding: none;
transform : scale(1.2);
font-size: 0.7rem; 
}

#buy-now {
  margin-top: 30px;
}

h3 {
  font-size: 2rem;
}

#ingredients {
  font-size: 1rem;
  padding-top: unset;
    margin-top: 20px;
}

.box{
  font-size: 2rem;
  margin-right: 40px;
}

  nav ul li {
    font-size: 2rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
</style>