<template>
  <div class="body">
    <section class="the-panel">
      <header id="logo">
        <div>
          <img id="headerImg" src="../assets/pic5.jpg" alt="logo" />

        </div>
      </header>

      <header>
        <router-link to="/" class="go-back-btn">Go Back</router-link>
      
      </header>

 <!-- This is the loading image in-between data fetching. -->
        <div v-if="isLoading">
          <p id="loading-l">Loading...</p>
        <img class="loading"  src="../assets/giphy.gif" />
        </div>

      <section></section>
 <!-- This whole h1 shows the dropdown menu with all the foods inside of it. -->
      <h1>
        <label id="myDropdown" class="dropdown-content"></label>

        <select id="drop" v-model="value" v-on:change="OVSearchResults">
           <option :value="''" disabled selected>Select a Food</option>
          <option v-for="option in foodoptions" v-bind:key="option.id">
            {{ option.name }}
          </option>
        </select>
        <!-- <br><br> -->
        <pre class="language-json"></pre>
      </h1>
  <h3 v-show="value !== ''" id="find-oils">Choose Oil Or Vinegar To See Food Pairing Options</h3>
  <!-- This whole div/table is displaying the OV results after choosing a food. -->   
        <div id="OVList">
          <table>
            <tr v-for="option in oilResults" v-bind:key="option.id">
               <div id ="results-background">
              <router-link v-bind:to="{name: 'specific-oil-results', params: { OVName: option.ovName }}">
                <h1 class="results">{{ option.ovName }}</h1>
           <img id="foodPics" v-bind:src="require('@/assets/' + option.img)"  loading="lazy" />
                <p> View All Food Matches For This Bottle</p>
              </router-link>
           {{ option.description }}
               </div>
              <br/>
            </tr>
          </table>
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
           <router-link to="/" class="go-back-btn">Go Back</router-link>
        </div>
     
 <!-- This whole footer is responsible for displaying social media icons and copyright. -->
      <footer>
        <ul id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-icons1"  class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/" target="_blank"
              ><i id="social-icons1"  class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ul>
        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
      </footer>
    </section>
  </div>
</template>



<script>
import services from "../services/services.js";
export default {
  data() {
    return {
      isLoading: true,
      value: "",
      foodoptions: [],
      oilResults: [],
    };
  },
  created() {
      // This getFoods() shows the dropdown menu with all the foodss inside of it.
    services
      .getFoods()
      .then((response) => {
        this.foodoptions = response.data;
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response) {
          alert("Not Available Option");
        }
      });
  },
// This OVSearchResults() is displaying the OV results after choosing a food.
  methods: {
    OVSearchResults() {
      services
        .getOVSearchResults(this.value)
        .then((response) => {
          this.oilResults = response.data;
               // not sure if isLoading needs to be here??
            this.filler();
        })
        .catch((error) => {
          if (error.response) {
            console.warn("Not Available Option");
          }
        });
    },
        filler() {
      for (let i=0; i < this.oilResults.length; i++) {
        if (this.oilResults[i].img !== "tangerine.jpg") {
          this.oilResults[i].img = "tangerine.jpg";
        }
      }
    }
  },
};
</script>



<style scoped>
/* .body  {
  display: flex;
  margin: 0 auto;
  padding: -4;
  min-height: 100vh;
 } */


#social-icons1 {
  font-size: 2.5rem;
}

#logo {
  width: auto;
    outline: 7px solid rgb(211, 207, 207);
  
}

#drop {
  color: rgb(0, 0, 0);
  background-color: rgba(146, 188, 195, 0.733);
  font-size: 1.8rem;
}

#OVList {
  
  outline: 5px solid rgb(211, 207, 207);
  color: black;
  font-size: 1.8rem;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}

#results-background {
  background-color:   rgba(132, 147, 161, 0.623);
  border: 2px solid black;
  border-radius: 15px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  font-size: 1.3rem;
}

.results {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: black;
  width: auto;
  font-size: 1.5rem;
  margin-top: 25px;
  margin-left: 25%;
  margin-right: 25%;
  padding-top: 10px;
  font-weight: bolder;
  border: 2.5px solid rgb(0, 0, 0);
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  background-color: rgba(132, 147, 161, 0.26);
}

p {
  color: rgb(53, 83, 165);
  text-decoration: underline black;
margin-bottom: 20px;
text-underline-offset: 6px;
}

#find-oils {
  display: flex;
  justify-content: center;
  color: black;
text-decoration: underline;
margin-bottom: 20px;
text-underline-offset: 6px;
margin-top: 15px;
}

#headerImg {
   display: flex;
  height: 300px;
  object-fit: cover;
  border: none;
  border-radius: inherit;

}

#description {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  border: solid;
  color: #000;
  padding: 15px;
  margin-bottom: 20px;
  background-color: rgba(132, 147, 161, 0.26);
}


.loading {
    transform: scale(0.7);
    border-radius: 15%;
}

#loading-l{
  text-decoration: unset;
  font-size: 1.3rem;
  color:  black;
}



div {
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  background-color: rgba(165, 177, 189, 0.904);
  font-weight: bold;
  /* padding: 20px; */
}

.the-panel {
  background-color: #b9b7b7;
}

#logo div > img {
  display: flex;
  max-height: auto;
  width: 100%;
  margin: 0 auto;
}

#social-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;
}
footer {
  /* grid-area: footer; */
  /* flex-grow: 1; */
  margin-bottom: 20px;
}


#trade-mark {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  list-style-type: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.609);
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 28px;
}

nav ul {
  list-style-type: none;
  margin: 0 10px 0 10px;
  padding: 0;
  text-align: center; /*left panel text direction*/
}

nav ul li {
  display: flex;
  display: inline-flex;
  justify-content: space-around;
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 20px;
  align-items: center;
}

nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

nav a:hover {
  color: #2591da; /*Hover for directory items*/
  padding-left: 5px;
}

.fab {
  color: #313131; /*app icon color*/
}

#social-icons :hover .fab {
  color: #2591da;
}

#trade-mark {
  color: #000;
}

#foodPics {
   width: 250px;
  height: 300px;
  padding-bottom: 30px;
  text-decoration: none;
  outline: none;
   border-radius: 15%;
}

@media (max-width: 1024px) {
  #the-panel {
    flex-basis: 100%;
    width: auto;
    height: 200px;
  }
 h2 {
    font-size: 1.9rem;
  }
  #go-back-btn {
    font-size: 1.8rem;
  }

#headerImg {
  height: 250px;
}
#foodPics {
   width: 250px;
  height: 350px;
}

  #results-background{
    font-size: 1rem;
  }


  h2 {
    font-size: 1.5rem;
  }

  .fa-3x {
    font-size: 1.5rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
@media (max-width: 450px) {

  #go-back-btn {
    font-size: 1.5rem;
  }

h3 {
  font-size: 1.2rem;
}

.results {
  font-size: 1.2rem;
}

#headerImg {
  height: 200px;
}

#foodPics {
    width: 250px;
  height: auto;
  padding: 5px;
}

  #results-background{
    font-size: 1.3rem;
  }

  nav ul li {
    font-size: 2rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
</style>