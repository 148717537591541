import axios from 'axios';
const http = axios.create({
    // baseURL: "https://api.spoonacular.com/recipes"
    // baseURL: "https://olive-vue.herokuapp.com"
    // baseURL: "http://localhost:8080"
});
export default {
    searchRecipes(searchTerm) {
        return http.get(`https://olive-vue.herokuapp.com/searchRecipes/${searchTerm}`);
    },
    getSpecificRecipe(id) {  
         return http.get(`https://olive-vue.herokuapp.com/specificRecipe/${id}`);
    },
    // filteredRecipes(informationBulk) {
    //     return http.get(`https://api.spoonacular.com/recipes/informationBulk?ids=${informationBulk}&includeNutrition=false&apiKey=`);
    // },

    getStepByStepInstructions(id){
        return http.get(`https://olive-vue.herokuapp.com/stepByStep/${id}`);
    },

    getIngredients(id){
    return http.get(`https://olive-vue.herokuapp.com/recipeIngredients/${id}`);
    }
}