<template>
  <div class="body">
    <section class="the-panel">
      <header id="logo">
        <div>
          <img id="headerImg" src="../assets/oils3.jpg" alt="logo" />
        </div>
      </header>
      <br />

 
      <router-link to="/" class="go-back-btn">Go Back</router-link>
 
      <!-- This is the loading image in-between data fetching. -->
      <div class="loading" v-if="isLoading">
        <p>Loading...</p>
        <img src="../assets/giphy.gif" />
      </div>
      <section></section>


      <!-- This whole h1 shows the dropdown menu with all the OVs inside of it. -->
      <h1>
        <label id="myDropdown" class="dropdown-content"></label>
        <select id="drop" v-model="value" v-on:change="searchResults">
          
          <option :value="''" disabled selected>Select a Oil or Vinegar</option>
          <option v-for="oilOption in oiloptions" v-bind:key="oilOption.id" >
            {{ oilOption.ovName }}
          </option>
        </select>
        <pre class="language-json"></pre>
      </h1>
  <h3 v-show="value !== ''" id="choice">These Foods Pair With Bottle Chosen</h3>
      <!-- This whole div/table is displaying the food results after choosing an OV. -->
      <div id="foodList">
        <table>
          
          <th v-show="value" id="description">{{ currentSelectedDescription }}  <p v-if="currentSelectedDescription" id="view-recipes">Click Picture To View Recipes</p></th>
         
          <tr v-for="option in foodResults" v-bind:key="option.id"  >
            <router-link v-bind:to="{name: 'list-of-food-recipes', params: { foodName: option.name}}">
            <div class="results"  v-on:click="storeOvName(value, option.name)" >
              <h2>{{ option.name }}</h2>
              <img v-bind:src="require(`../assets/${option.foodImg}`)" loading="lazy" />
              
            </div>
            </router-link>
          </tr>
        </table>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <router-link to="/" class="go-back-btn">Go Back</router-link>
      </div>


      <!-- This whole footer is responsible for displaying social media icons and copyright. -->
      <footer>
        <ul id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-iconsl" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-iconsl" class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/" target="_blank"
              ><i id="social-iconsl" class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ul>

        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
      </footer>
    </section>
  </div>
</template>



<script>
  import services from "../services/services.js";
export default {
  name: 'specific-oil-results',
  data() {
    return {
      isLoading: true,
      currentSelectedDescription: "",
      foodPic: "",
      storeName: { oilOption: "", foodPath: ""},
      value: "",
      oiloptions: [],
      foodResults: []
    };
  },
  created() {
    // This getOils() shows the dropdown menu with all the OVs inside of it.
    // This is also what is bringing the params over from FoodResults.vue to this component, so the dropdown has OV already chosen from previous page.
   services
      .getOils()
      .then((response) => {
     services
        this.oiloptions = response.data;
        this.isLoading = false;

        if(this.$route.params.OVName !== "all"){
        this.value = this.$route.params.OVName;
        } else this.value = "";

   if (this.value !== "" ){
          this.searchResults();
        } else if (this.value == ""){
     return;
        }
      })
      .catch((error) => {
        if (error.response) {
          console.warn("Ok");
        }
   });
  },
  mounted() {
window.scrollTo(0, 0);
  },
  // This searchResults() is displaying the food results after choosing an OV.
  computed: {},
  methods: {
    searchResults() {         
   if  (this.value !== "all") {
      services
        .getFoodSearchResults(this.value)
        .then((response) => {
          this.foodResults = response.data;
          this.filler();
          this.currentSelectedDescription = response.data[0].ovDescription;
          this.isLoading = false;
      })
      .catch((error) => {
        if (error) {
          console.log("")
        }
   });
 }
    },
    // This is storing the URL link to buy that OV from blue herons ecommerce page to the "store"(index.js) so we can bring this link to other components. 
    storeOvName(oilOption, foodPath){
      this.storeName.oilOption = oilOption;
      this.storeName.foodPath = foodPath;
      let blank = "";
      this.$store.commit("STORE_OV_NAME_AND_LINK", blank);
      this.$store.commit("STORE_OV_NAME_AND_LINK", this.storeName);
      this.OVFromStore = this.$store.state.oVName;
    },
    // In the event there's no picture, this will display a (filler) image temporarily.
    filler() {
      for (let i = 0; i < this.foodResults.length; i++) {
        if (this.foodResults[i].foodImg == null) {
          this.foodResults[i].foodImg = "pasta.jpg";
        }
      }
    },

  },
};
</script>



<style scoped>
 .body {
   display: inline-block;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  }

#logo {
  width: auto;
  outline: 7px solid rgb(211, 207, 207);
  border: solid;
}

#drop {
  color: rgb(0, 0, 0);
  background-color: rgba(146, 188, 195, 0.733);
  padding: 5px;
  font-size: 26px;
}

#foodList {
  outline: 7px solid rgb(211, 207, 207);
  width: 100%;
  font-size: 1.8rem;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}

#view-recipes {
  color: rgb(18, 42, 109);
  font-size: 1.2rem;

}

h2 {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  padding-bottom: 8px;
  padding-top: 8px;
  font-weight: bolder;
  color: #000;
  border: 3px solid #000;
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  background-color:  rgba(165, 177, 189, 0.904);
  
}

#choice {
  display: flex;
  justify-content: center ;
color: black;
text-decoration: underline;
margin-bottom: 20px;
text-underline-offset: 6px;
font-size: 1.8rem;
}

td {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  margin-top: 20px;
}

#description {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  border: solid 3px black;
  border-radius: 5px;
  color: #000;
  padding: 15px;
   background-color: rgba(132, 147, 161, 0.349);
}

.loading {
  display: flex;
  justify-content: center;
}

h1 {
font-size: 1rem;
  display: flex;
  justify-content: center;
}

.results {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2px;
  margin-right: 2px;
  border: 3px solid #000;
  background-color: rgba(72, 86, 100, 0.678);
  border-radius: 5%;
  height: 340px;
  width: auto;
}

div {
  display: block;
  justify-content: center;
  padding: 20px;
  background-color: rgba(165, 177, 189, 0.904);
}

.the-panel {
  background-color: #b9b7b7;
}

table {
  display: flex;
  justify-content: space-around;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
}

#logo div > img {
  display: flex;
  max-height: auto;
  width: 100%;
  margin: 0 auto;
}

#social-icons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;
}

#social-iconsl{
  font-size: 2.5rem;
}

footer {
  grid-area: footer;
  flex-grow: 1;
}

#trade-mark {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  list-style-type: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.609);
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 28px;
}

nav ul {
  list-style-type: none;
  margin: 0 10px 0 10px;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: flex;
  justify-content: space-around;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 20px;
  align-items: center;
}

nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

#go-back-btn a:hover {
  color: #2591da; 
  padding-left: 5px;
}

.fab {
  color: #313131; 
}

#social-icons :hover .fab {
  color: #2591da;
}

#trade-mark {
  color: #000;
}
#headerImg {
  display: flex;
  height: 300px;
  border: none;
  border-radius: inherit;
}

img {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 250px;
  height: auto;
  border-radius: 15%;
  border: solid 4px black;
}

@media (max-width: 1024px) {

#description {
  font-size: 1.2rem;
}

#headerImg {
  height: 250px;
  width: auto;
}

  #go-back-btn {
    font-size: 1.8rem;
  }

.results{
  height: 240px;
  width: 200px;
}
img {
  width: 140px;
  height: 150px;
  margin: unset;
}

  .fa-3x {
    font-size: 1.5rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
@media (max-width: 450px) {
#headerImg {
  height: 150px;
  width: auto;
}
#drop {
  font-size: 1rem;
}

  #go-back-btn {
    font-size: 1.5rem;
  }
  nav ul li {
    font-size: 1rem;
  }

  ul.social-icons {
    margin-top: 20px;
    color: #313131;
  }
}
</style>