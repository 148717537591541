import Vue from 'vue'
import VueRouter from 'vue-router'
import TheHome from '../components/TheHome.vue'
import OilResults from '../components/OilResults.vue'
import FoodResults from '../components/FoodResults.vue'
import ListOfRecipes from '../components/ListOfRecipes.vue'
import SpecificRecipe from  '../components/SpecificRecipe.vue'
import ContactUs from '../components/ContactUs.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: TheHome
  },

  { 
    path: '/oil-results/:OVName',
    name: 'specific-oil-results',
    component: OilResults
  },

  { 
    path: '/list-of-recipes/:foodName',
    name: 'list-of-food-recipes',
    component: ListOfRecipes
  },

  { 
    path: '/specific-recipe/:specificRecipe',
    name: 'specific-recipe',
    component: SpecificRecipe
  },

  {
    path: '/food-results',
    name: 'food-results',
    component: FoodResults
  },

  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
