<template>
  <div class="body">
    <section class="the-panel">
      <header id="logo">
        <div>
          <img id="headerImg" src="../assets/fire2.jpg" alt="logo" />
        </div>
      </header>

      <header>
        <router-link to="/" class="go-back-btn">Go Back</router-link>
      </header>

 <!-- This is the loading image in-between data fetching. -->
        <div class="loading" v-if="isLoading">
        <img src="../assets/giphy.gif" />
        </div>

      <section></section>
      <div>
<h1>Phone:</h1> <a href="tel:17347897986" target="_blank"><p>(734)-789-7986</p></a><br>
<h1>Email:</h1> <a href="mailto:sales@blueherontradingcompany.com" target="_blank"><p>sales@blueherontradingcompany.com</p></a>

      </div>
      <div>
        <h1>Address:</h1>
<a href="https://www.google.com/maps/place/Blue+Heron+Trading+Company/@42.0949033,-83.2943923,17z/data=!3m1!4b1!4m5!3m4!1s0x883b40e1f48ee06b:0xf4b80c01aa3f97d1!8m2!3d42.0949033!4d-83.2922036" target="_blank"> <p>26217 East Huron River Dr Flat Rock Michigan 48134</p>
        <img id="map" src="../assets/map.jpg" alt="map" />
        </a>
      </div>
      <div>
       
      </div>
      <footer>
        <ul id="social-icons">
          <li>
            <a
              href="https://www.facebook.com/Blueherontradingcompany/"
              target="_blank"
              ><i id="social-icons1" class="fab fa-facebook fa-3x"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/blue_heron_trading_company/"
              target="_blank"
              ><i id="social-icons1"  class="fab fa-instagram fa-3x"></i
            ></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/BlueHeronTradingCompany/_saved/" target="_blank"
              ><i id="social-icons1"  class="fab fa-pinterest fa-3x"></i
            ></a>
          </li>
        </ul>
      </footer>
        <footer id="trade-mark">
          &copy; Blue Heron Trading Company All Rights Reserved 2021
        </footer>
    </section>
  </div>
</template>



<script>
import services from "../services/services.js";
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    services
        this.isLoading = false;
  },
  methods: {},
};
</script>



<style scoped>
.body {
  display: flex;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
}

#logo {
  width: auto;
    outline: 7px solid rgb(211, 207, 207);
  
}

#drop {
  color: rgb(0, 0, 0);
  background-color: rgba(146, 188, 195, 0.733);
  font-size: 1.8rem;
}

#results-background {
    background-color:   rgba(132, 147, 161, 0.623);
border: 2px solid black;
      border-radius: 15px;
    box-shadow: 0 0 0 2pt rgb(211, 207, 207);
}

#map {
    height: 300px;
    width: auto;
    margin-top: 20px;
}

p {
  color: rgb(53, 83, 165);
  text-decoration: underline black;
margin-bottom: 20px;
text-underline-offset: 6px;
}


#headerImg {
   display: flex;
  height: 400px;
  object-fit: cover;
  border: none;
  border-radius: inherit;

}

h1 {
  font-size: 1.7rem;
}

.body {
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  zoom: 65%; 
   /* overflow-y: hidden;  */
}
 
 /* html, .body {
    max-width: 100%;
      zoom: 55%;
    overflow-x: hidden; 
    display: flex;
  margin: 0 auto;
}  */


.loading {
  display: flex;
  justify-content: center;
}


div {
  border-radius: 5px;
  box-shadow: 0 0 0 2pt rgb(211, 207, 207);
  background-color: rgba(165, 177, 189, 0.904);
  font-weight: bold;
  padding: 20px;
}

.the-panel {
  background-color: #b9b7b7;
}

#logo div > img {
  display: flex;
  max-height: auto;
  width: 100%;
  margin: 0 auto;
}

#social-icons {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  list-style-type: none;
  margin: 20px 20px;
  padding-right: 50px;
  padding-left: 50px;
}

#social-icons1 {
  font-size: 2.5rem;
}

footer {
  grid-area: footer;
  flex-grow: 1;
}


#trade-mark {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  list-style-type: none;
  font-family: "Oswald", sans-serif;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.609);
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 28px;
}

nav ul {
  list-style-type: none;
  margin: 0 10px 0 10px;
  padding: 0;
  text-align: center; /*left panel text direction*/
}



nav a,
nav a:visited {
  text-decoration: none;
  color: #1b1b1b;
}

nav a:hover {
  color: #2591da; /*Hover for directory items*/
  padding-left: 5px;
}

.fab {
  color: #313131; /*app icon color*/
}

#social-icons :hover .fab {
  color: #2591da;
}

#trade-mark {
  color: #000;
}


@media (max-width: 1024px) {

 h1 {
    font-size: 1.9rem;
  }
  #go-back-btn {
    font-size: 1.8rem;
  }

#headerImg {
  height: 300px;
}

  #results-background{
    font-size: 1.6rem;
  }

  .body {
    display: flex;
    min-height: 100%;
    width: auto; 
    height: fit-content;
  }
 
  .fa-3x {
    font-size: 1.5rem;
  }

  ul.social-icons {
    margin-top: 20px;
  }
}
@media (max-width: 450px) {

  #go-back-btn {
    font-size: 1.5rem;
  }

h1 {
  font-size: 1.5rem;
}

#headerImg {
  height: 150px;
}


  #results-background{
    font-size: 1.3rem;
  }

 nav ul li {
    font-size: 1rem;
  }
#map {
    height: 200px;
    width: 300px;
}
  #social-icons {
    margin-top: 20px;
    padding: unset;
  }

}
</style>