import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    oVName: '',
    foodPath: '',
    oVLink: '',
  },
  mutations: {
    STORE_OV_NAME_AND_LINK(state, storeName) {
      state.oVName = storeName.oilOption;
      state.foodPath = storeName.foodPath;
    },

    STORE_OV_LINK(state, oVLink){
      state.oVLink = oVLink;
    }
  },
  actions: {
  },
  modules: {
  }
})
